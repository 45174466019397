import React ,{useState,useEffect}from "react";
import "./style/home.css";
import "./style/slide.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "./footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPlus,faMinus, faPaperPlane,faVolleyball,faCheckToSlot, faCheckDouble} from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import "./style/home2.css"
import "./style/home.css"
import axios from "axios";
import { useLocation } from "react-router-dom"
import "./style/background.css"
import {faArrowRight,faArrowLeft} from "@fortawesome/free-solid-svg-icons"


const Home2=() =>{

  const wegoUrl = 'https://wegotips.com/api';


  const [show,setShow]= useState(false)
  const [show1,setShow1]= useState(false)
  const [show2,setShow2]= useState(false)
  const [show3,setShow3]= useState(false)
  const [show4,setShow4]= useState(false)
  const [show5,setShow5]= useState(false)
  const [show6,setShow6]= useState(false)
  const [show7,setShow7]= useState(false)

  const [sports,setSports]= useState("Forex")
  const [loading,setLoading] = useState(true)
  const [prediction,setPrediction] = useState("sportsPre")
  const [currentPrediction,setCurrentPrediction] = useState("cuForexPre")
  

 // table  content
 const tableContent = ["content","content","content","content","content"]
 const tableContent1 = ["content","content","content","content","content"]
 const tableContent2 = ["content","content","content","content","content"]
 const tableContent3 = ["content","content","content","content","content"]






  const sportItem = [{game_no:1,profit:"20",odd:"2.5",action:"loss"},{game_no:2,profit:"35",odd:"4.5",action:"earned"},{game_no:3,profit:"55",odd:"0.5",action:"earned"},{game_no:4,profit:"70",odd:"4.5",action:"earned"}]
   const cryptoItem = [{c_trade_no:1,c_pair:"VBN/BTC",c_condition:"long",c_entry_point:"500",c_exit_point:"700",c_check:"loss",c_take_profit:"460"},{c_trade_no:2,c_pair:"AGRO/USDC",c_condition:"long",c_entry_point:"410",c_exit_point:"400",c_check:"earned",c_take_profit:"460"},{c_trade_no:3,c_pair:"UTC/USDT",c_condition:"long",c_entry_point:"300",c_exit_point:"400",c_check:"earned",c_take_profit:"460"},{c_trade_no:4,c_pair:"YTY/USDT",c_condition:"long",c_entry_point:"500",c_exit_point:"300",c_check:"loss",c_take_profit:"460"}]
    const forexItem = [{r_trade_no:1,r_pair:"BTC/USDT",r_condition:"long",r_entry_point:"500",r_exit_point:"700",r_take_profit:"460",r_check:"loss"},{r_trade_no:2,r_pair:"PAC/USDC",r_condition:"long",r_entry_point:"410",r_exit_point:"400",r_take_profit:1000,r_check:"earned"},{r_trade_no:3,r_pair:"EAR/BTC",r_condition:"long",r_entry_point:"300",r_exit_point:"400",r_take_profit:1000,r_check:"earned"},{r_trade_no:4,r_pair:"HTC/USDT",r_condition:"long",r_entry_point:"500",r_exit_point:"300",r_take_profit:1000,r_check:"loss"}]
     const binaryItem = [{b_trade_no:1,b_pair:"BTC/USDC",b_condition:"long",b_expire_time:"6:00",b_profit:"700",b_check:"loss"},{b_trade_no:2,b_pair:"YKC/USDC",b_condition:"short",b_expire_time:"3:35",b_profit:"800",b_check:"earned"},{b_trade_no:3,b_pair:"ADC/USDT",b_condition:"long",b_expire_time:"4:00",b_profit:"40",b_check:"loss"},{b_trade_no:4,b_pair:"KCK/USDC",b_condition:"long",b_expire_time:"5:00",b_profit:"800",b_check:"earned"}]
  const drop =()=>{
     setShow(!show)
     setShow1(false)
     setShow2(false)
     setShow3(false)
     setShow4(false)
     setShow5(false)
     setShow6(false)
     setShow7(false)
  }
  const drop1 =()=>{
    setShow(false)
    setShow1(!show1)
    setShow2(false)
    setShow3(false)
    setShow4(false)
    setShow5(false)
    setShow6(false)
    setShow7(false)
    
  }
  const drop2 =()=>{

    setShow(false)
    setShow1(false)
    setShow2(!show2)
    setShow3(false)
    setShow4(false)
    setShow5(false)
    setShow6(false)
    setShow7(false)
    
  }
  const drop3 =()=>{
    setShow(false)
    setShow1(false)
    setShow2(false)
    setShow3(!show3)
    setShow4(false)
    setShow5(false)
    setShow6(false)
    setShow7(false)
  }
  const drop4 =()=>{

    setShow(false)
    setShow1(false)
    setShow2(false)
    setShow3(false)
    setShow4(!show4)
    setShow5(false)
    setShow6(false)
    setShow7(false)
    
  }
  const drop5 =()=>{
    setShow(false)
    setShow1(false)
    setShow2(false)
    setShow3(false)
    setShow4(false)
    setShow5(!show5)
    setShow6(false)
    setShow7(false)
  }
  const drop6 =()=>{
    setShow(false)
    setShow1(false)
    setShow2(false)
    setShow3(false)
    setShow4(false)
    setShow5(false)
    setShow6(!show6)
    setShow7(false)
  }
  const drop7 =()=>{
    setShow(false)
    setShow1(false)
    setShow2(false)
    setShow3(false)
    setShow4(false)
    setShow5(false)
    setShow6(false)
    setShow7(!show7)
  }
 const [error,setError]=useState("")
  const [posts1,setPosts1] = useState([])
   const [post,setPosts] = useState([])
   const [post3,setPosts3] = useState([])
   const [post4,setPosts4] = useState([])
  const cat = useLocation().search
  



  const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const date = `  ${current.getFullYear()}/${current.getMonth()+1}/${current.getDate()}`;

  // const tr = window.location.pathname;
  // if(tr === "/"){
  //   console.log("Mr kingsley is Good and nan")
  // }


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  const responsive1 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 70,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 70,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  
  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }



  const ButtonOne =({onClick})=>{
  return(
    <div className='postKin' onClick={onClick}><FontAwesomeIcon icon={faArrowLeft}   style={{fontSize:"15px"}} /></div>
  )
}

const ButtonTwo =({onClick})=>{
  return(
    <div className='postKin' onClick={onClick}><FontAwesomeIcon icon={faArrowRight}   style={{fontSize:"15px"}} /></div>
  )
};

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div className="carousel-button-group"> 
      <ButtonOne className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
      <ButtonTwo onClick={() => next()} />
     
    </div>
  );
};



  const forf =()=>{
      setSports('Forex')
  }
  const crypto =()=>{
    setSports("cryptocurrency")

}
const bina =()=>{
  setSports('binary')
}
const Sports1 =()=>{
    setSports("sports")
}

// useEffect(()=>{
//   const fetchData4 = async ()=>{
//     try{
//  const res = await axios.get(`myPort/post/binary/tip3`)
//    setPosts4(res.data)

//     }catch(err){
//     setError(err)

//     }
//   }
//   fetchData4()
// },[post4])

useEffect(()=>{
  const fetchData4 = async ()=>{

  await axios.get(`${wegoUrl}/post/binary/tip3`).then((response)=>{
setPosts4(response.data)
setLoading(false)
  }).catch((err)=>{
  // setError(err)
  console.log(err)
  }) 
  }
  fetchData4()
},[post4])

// useEffect(()=>{
//   const fetchData3 = async ()=>{
//     try{
//  const res = await axios.get(`https://tea.earnars.com/api/post/crypto/tip3`)
//    setPosts3(res.data)
 
//     }catch(err){
//     setError(err)

//     }
//   }
//   fetchData3()
// },[post3])

useEffect(()=>{
  const fetchData3 = async ()=>{
  await axios.get(`${wegoUrl}/post/crypto/tip3`).then((response)=>{
  setPosts3(response.data)
  setLoading(false)
  }).catch((err)=>{
      //  setError(err)
      console.log(err)
  })
  
  }
  fetchData3()
},[post3])

 
useEffect(()=>{
  const fetchData2 = async ()=>{
 
 await axios.get(`${wegoUrl}/post/forex/tip3`).then((response)=>{
  setPosts(response.data)
  console.log(response.data)
  setLoading(false)
 }).catch((err)=>{
//  setError(err)
 console.log(err)
 })
    
  }

   fetchData2()
 },[post])

// useEffect(()=>{
//   const fetchData1 = async ()=>{
//     try{
//  const res = await axios.get(`https://tea.earnars.com/api/post/sports/tip3`)
//    setPosts1(res.data)
//     }catch(err){
//        setError(err)

//     }
//   }
//   fetchData1()
// },[post1])


useEffect(()=>{
  const fetchData1 = async ()=>{
 
 await axios.get(`${wegoUrl}/post/sports/tip3`).then((response)=>{
   setPosts1(response.data)
  
   setLoading(false)
 }).catch((err)=>{
  // setError(err)
  console.log(err)
 }) 
  }
  fetchData1()
},[posts1])

  useEffect(()=>{
    AOS.init({once: true});
  },[])
  

    return (
      <div  className="HomPageBg">
        <div className="ahh"></div>
      <div className="Home_Bg_Bg" >
  
         <div className="carousel_Div honeDivGuide lovePa">
              <img src="/img/tom.png" className="ImgDiv" />
            </div>
            <div className="Lg">
              <div className="sportPa"  >
            <div className="affiliateImage" >
                    <div className="grace">
                        <h1 className="Aka">Explore the market</h1>
                        {/* <div><span className="homeSpan">Home</span> <span className="homeSpan">Affiliate program</span></div> */}
                       <p className="weAre4">confidently with our  </p>
                       <p className="withUpto">top-notch forecasts</p>
                       <p className='everyWhere' style={{marginBottom:"10px"}}>and discover  your earning potential.</p>
                       {/* <div className='football'>Football, Tennis, Basketball ETC</div> */}
                    </div>
                     <div className="grace">
                      {/* <img src="/img/predictions2.svg" className="gracePic WEW" alt="affiliate_banner"/> */}
                      <svg className="gracePic WEW" width="564" height="510" viewBox="0 0 564 510" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7_2)">
<path d="M376.396 179.33H187.775C184.538 179.326 181.433 178.04 179.144 175.753C176.854 173.466 175.566 170.365 175.563 167.13V19.145C175.565 16.7754 176.509 14.5037 178.186 12.8282C179.863 11.1526 182.138 10.2101 184.51 10.2073H379.507C381.92 10.2101 384.233 11.1689 385.94 12.8734C387.646 14.5779 388.606 16.889 388.609 19.2995V167.13C388.605 170.365 387.317 173.466 385.028 175.753C382.738 178.04 379.634 179.326 376.396 179.33Z" fill="#312264"/>
<path d="M199.618 167.13H364.553C367.694 167.13 370.706 165.884 372.927 163.666C375.148 161.447 376.396 158.438 376.396 155.301V41.7738C376.396 38.6363 375.148 35.6274 372.927 33.4089C370.706 31.1904 367.694 29.944 364.553 29.944H199.618C196.477 29.944 193.465 31.1904 191.244 33.4089C189.023 35.6274 187.775 38.6363 187.775 41.7738V155.301C187.775 158.438 189.023 161.447 191.244 163.666C193.465 165.884 196.477 167.13 199.618 167.13Z" fill="#171054"/>
<path d="M388.439 18.4471H175.391V12.2362C175.396 8.99113 176.689 5.88037 178.987 3.58643C181.285 1.29249 184.4 0.0026908 187.648 0H376.182C379.43 0.00266539 382.545 1.29246 384.843 3.5864C387.141 5.88035 388.434 8.99111 388.439 12.2362V18.4471Z" fill="#271F70"/>
<path d="M197.667 12.409C199.418 12.409 200.837 10.9911 200.837 9.24199C200.837 7.49292 199.418 6.07501 197.667 6.07501C195.916 6.07501 194.496 7.49292 194.496 9.24199C194.496 10.9911 195.916 12.409 197.667 12.409Z" fill="white"/>
<path d="M209.701 12.409C211.452 12.409 212.871 10.9911 212.871 9.24199C212.871 7.49292 211.452 6.07501 209.701 6.07501C207.95 6.07501 206.53 7.49292 206.53 9.24199C206.53 10.9911 207.95 12.409 209.701 12.409Z" fill="white"/>
<path d="M221.735 12.409C223.486 12.409 224.905 10.9911 224.905 9.24199C224.905 7.49292 223.486 6.07501 221.735 6.07501C219.984 6.07501 218.564 7.49292 218.564 9.24199C218.564 10.9911 219.984 12.409 221.735 12.409Z" fill="white"/>
<path d="M211.755 83.1453H203.587C203.144 83.1448 202.719 82.9688 202.406 82.6559C202.093 82.343 201.917 81.9188 201.916 81.4763V73.3167C201.917 72.8742 202.093 72.45 202.406 72.1371C202.719 71.8242 203.144 71.6482 203.587 71.6477H211.755C212.198 71.6482 212.623 71.8242 212.936 72.1371C213.249 72.45 213.426 72.8742 213.426 73.3167V81.4763C213.426 81.9188 213.249 82.343 212.936 82.6559C212.623 82.9688 212.198 83.1448 211.755 83.1453Z" fill="#6C63FF"/>
<path d="M211.755 104.286H203.587C203.144 104.286 202.719 104.11 202.406 103.797C202.093 103.484 201.917 103.06 201.916 102.617V94.4574C201.917 94.015 202.093 93.5907 202.406 93.2778C202.719 92.9649 203.144 92.7889 203.587 92.7885H211.755C212.198 92.7889 212.623 92.9649 212.936 93.2778C213.249 93.5907 213.426 94.015 213.426 94.4574V102.617C213.426 103.06 213.249 103.484 212.936 103.797C212.623 104.11 212.198 104.286 211.755 104.286Z" fill="#3F3D56"/>
<path d="M211.755 125.427H203.587C203.144 125.426 202.719 125.25 202.406 124.937C202.093 124.624 201.917 124.2 201.916 123.758V115.598C201.917 115.156 202.093 114.731 202.406 114.419C202.719 114.106 203.144 113.93 203.587 113.929H211.755C212.198 113.93 212.623 114.106 212.936 114.419C213.249 114.731 213.426 115.156 213.426 115.598V123.758C213.426 124.2 213.249 124.624 212.936 124.937C212.623 125.25 212.198 125.426 211.755 125.427Z" fill="#CCCCCC"/>
<path d="M265.221 79.0655H225.864C225.422 79.0646 224.998 78.8883 224.685 78.5754C224.373 78.2625 224.197 77.8385 224.197 77.3965C224.197 76.9544 224.373 76.5305 224.685 76.2176C224.998 75.9047 225.422 75.7284 225.864 75.7275H265.221C265.441 75.727 265.658 75.7699 265.862 75.8535C266.065 75.9372 266.249 76.0601 266.405 76.2151C266.56 76.3701 266.684 76.5543 266.768 76.757C266.852 76.9597 266.895 77.177 266.895 77.3965C266.895 77.616 266.852 77.8333 266.768 78.036C266.684 78.2387 266.56 78.4229 266.405 78.5779C266.249 78.7329 266.065 78.8558 265.862 78.9395C265.658 79.0231 265.441 79.066 265.221 79.0655Z" fill="#CCCCCC"/>
<path d="M265.221 100.206H225.864C225.422 100.205 224.998 100.029 224.685 99.7161C224.373 99.4032 224.197 98.9792 224.197 98.5372C224.197 98.0951 224.373 97.6711 224.685 97.3582C224.998 97.0453 225.422 96.8691 225.864 96.8682H265.221C265.664 96.8682 266.089 97.044 266.402 97.357C266.716 97.67 266.892 98.0945 266.892 98.5372C266.892 98.9798 266.716 99.4043 266.402 99.7173C266.089 100.03 265.664 100.206 265.221 100.206Z" fill="#CCCCCC"/>
<path d="M265.221 121.347H225.864C225.422 121.346 224.998 121.17 224.685 120.857C224.373 120.544 224.197 120.12 224.197 119.678C224.197 119.236 224.373 118.812 224.685 118.499C224.998 118.186 225.422 118.01 225.864 118.009H265.221C265.441 118.008 265.658 118.051 265.862 118.135C266.065 118.219 266.249 118.341 266.405 118.497C266.56 118.652 266.684 118.836 266.768 119.038C266.852 119.241 266.895 119.458 266.895 119.678C266.895 119.897 266.852 120.115 266.768 120.317C266.684 120.52 266.56 120.704 266.405 120.859C266.249 121.014 266.065 121.137 265.862 121.221C265.658 121.305 265.441 121.347 265.221 121.347Z" fill="#CCCCCC"/>
<path d="M362.19 99.0673C362.232 98.3657 362.255 97.6592 362.255 96.947C362.255 87.6685 358.565 78.77 351.997 72.2091C345.429 65.6482 336.521 61.9623 327.233 61.9623V99.0673H362.19Z" fill="#464195"/>
<path className="bk" d="M324.58 102.248V65.1427C317.743 65.1424 311.056 67.1408 305.343 70.8915C299.63 74.6422 295.141 79.9811 292.429 86.2499C289.717 92.5187 288.901 99.4433 290.082 106.17C291.263 112.896 294.388 119.13 299.074 124.104C303.759 129.077 309.799 132.571 316.448 134.157C323.098 135.742 330.067 135.348 336.495 133.025C342.924 130.701 348.531 126.548 352.625 121.08C356.72 115.611 359.122 109.064 359.536 102.248H324.58Z" fill="#3F3D56"/>
<path d="M373.55 305.3C373.797 305.635 374.017 305.989 374.209 306.358L411.3 310.525L416.695 303.244L428.759 309.94L418.139 327.625L372.697 315.712C371.541 316.823 370.073 317.555 368.49 317.812C366.907 318.07 365.283 317.839 363.834 317.152C362.385 316.464 361.179 315.353 360.378 313.964C359.577 312.576 359.218 310.977 359.349 309.38C359.48 307.783 360.094 306.264 361.111 305.024C362.127 303.784 363.497 302.883 365.039 302.44C366.58 301.997 368.22 302.034 369.741 302.545C371.261 303.055 372.59 304.016 373.55 305.3Z" fill="#FFB8B8"/>
<path d="M184.29 295.734C183.352 296.285 182.304 296.624 181.221 296.726C180.138 296.829 179.045 296.693 178.02 296.328C176.995 295.963 176.063 295.379 175.288 294.615C174.514 293.851 173.917 292.927 173.539 291.908L147.095 292.459L154.421 280.842L178.409 282.1C180.166 281.579 182.052 281.719 183.711 282.495C185.37 283.271 186.687 284.627 187.412 286.308C188.137 287.989 188.219 289.876 187.644 291.614C187.068 293.351 185.875 294.817 184.29 295.734Z" fill="#FFB8B8"/>
<path d="M172.668 292.409C172.458 293.109 172.023 293.721 171.431 294.15C170.838 294.58 170.121 294.802 169.39 294.785L120.993 293.557L77.1712 280.596C75.9654 280.239 74.8425 279.647 73.8679 278.853C72.8933 278.059 72.0864 277.08 71.4942 275.971C70.9019 274.863 70.5362 273.648 70.4181 272.398C70.3001 271.147 70.4321 269.885 70.8066 268.686C71.181 267.487 71.7904 266.374 72.5993 265.412C73.4081 264.45 74.4004 263.658 75.5183 263.083C76.6362 262.508 77.8575 262.16 79.1111 262.06C80.3647 261.96 81.6257 262.111 82.8207 262.502L122.412 275.469L169.931 280.05C170.763 280.134 171.534 280.527 172.091 281.15C172.648 281.773 172.951 282.583 172.941 283.418L172.804 291.515C172.799 291.818 172.753 292.118 172.668 292.409Z" fill="#33298A"/>
<path d="M102.483 500.251L111.557 500.25L115.874 465.287L102.481 465.288L102.483 500.251Z" fill="#FFB8B8"/>
<path d="M100.168 497.291L118.038 497.29H118.039C119.535 497.29 121.015 497.584 122.397 498.156C123.779 498.728 125.034 499.566 126.092 500.622C127.149 501.678 127.988 502.932 128.561 504.313C129.133 505.693 129.427 507.172 129.427 508.666V509.036L100.169 509.037L100.168 497.291Z" fill="#2F2E41"/>
<path d="M75.8373 500.251L84.9109 500.25L89.2282 465.287L75.8358 465.288L75.8373 500.251Z" fill="#FFB8B8"/>
<path d="M73.5224 497.291L91.3926 497.29H91.3934C92.8889 497.29 94.3698 497.584 95.7515 498.156C97.1332 498.728 98.3887 499.566 99.4462 500.622C100.504 501.678 101.343 502.932 101.915 504.313C102.487 505.693 102.782 507.172 102.782 508.666V509.036L73.523 509.037L73.5224 497.291Z" fill="#2F2E41"/>
<path d="M69.7473 358.953L71.914 487.375C71.9209 487.782 72.0116 488.183 72.1806 488.553C72.3497 488.923 72.5933 489.254 72.8963 489.526C73.1992 489.798 73.5551 490.004 73.9415 490.132C74.328 490.26 74.7368 490.308 75.1423 490.271L85.7607 489.306C86.4572 489.243 87.1088 488.936 87.6 488.438C88.0913 487.941 88.3904 487.286 88.4445 486.59L94.6609 406.484C94.6755 406.296 94.7616 406.12 94.9015 405.993C95.0415 405.867 95.2246 405.798 95.4135 405.802C95.6024 405.805 95.7827 405.881 95.9175 406.013C96.0523 406.146 96.1314 406.324 96.1385 406.513L99.2457 488.453C99.2746 489.217 99.599 489.941 100.151 490.471C100.702 491.002 101.438 491.298 102.204 491.298H113.592C114.346 491.298 115.071 491.011 115.621 490.495C116.17 489.979 116.501 489.273 116.547 488.521L124.519 358.214L77.1488 346.384L69.7473 358.953Z" fill="#2F2E41"/>
<path d="M104.106 238.366C114.146 238.366 122.285 230.236 122.285 220.207C122.285 210.178 114.146 202.048 104.106 202.048C94.0663 202.048 85.9272 210.178 85.9272 220.207C85.9272 230.236 94.0663 238.366 104.106 238.366Z" fill="#FFB8B8"/>
<path d="M123.822 363.714C123.804 363.714 123.787 363.714 123.769 363.714L81.2612 363.06C80.4441 363.048 79.6602 362.735 79.0585 362.183C78.4569 361.631 78.0795 360.877 77.9983 360.064L77.5171 355.256C77.5079 355.165 77.4652 355.081 77.3971 355.019C77.3291 354.958 77.2406 354.924 77.1488 354.924C77.057 354.924 76.9685 354.958 76.9005 355.019C76.8324 355.081 76.7897 355.165 76.7805 355.256L76.2834 360.223C76.2063 361 75.8574 361.725 75.2981 362.271C74.7387 362.817 74.0045 363.149 73.2247 363.208L70.2207 363.439C69.6912 363.48 69.1597 363.394 68.6704 363.188C68.1812 362.981 67.7484 362.661 67.4081 362.254C67.0678 361.847 66.8299 361.364 66.7143 360.847C66.5987 360.329 66.6087 359.791 66.7434 359.278L69.33 349.427C74.6824 328.953 75.113 307.504 70.5866 286.833L69.3013 280.992C68.3323 276.629 68.7758 272.071 70.5677 267.976C72.3596 263.881 75.4074 260.461 79.2713 258.208L87.6205 253.319L91.4086 247.643C92.1416 246.547 93.1283 245.644 94.2851 245.011C95.4418 244.377 96.7344 244.032 98.0534 244.003C103.338 243.888 112.402 244.854 114.105 252.573C118.326 254.013 121.981 256.753 124.545 260.398C127.109 264.044 128.451 268.407 128.377 272.862L127.151 360.433C127.138 361.307 126.782 362.141 126.16 362.755C125.537 363.369 124.697 363.714 123.822 363.714Z" fill="#33298A"/>
<path d="M102.684 230.675C102.61 233.242 100.826 234.727 98.6352 236.071C96.4448 237.416 93.8843 237.999 91.3758 238.561C91.0389 238.74 90.6616 238.829 90.2802 238.82C89.8989 238.81 89.5264 238.703 89.1987 238.508C88.871 238.313 88.5991 238.037 88.4093 237.706C88.2196 237.376 88.1183 237.002 88.1153 236.621L85.8146 227.879C85.0007 225.087 84.4084 222.235 84.0433 219.349C83.4893 213.797 84.8064 207.825 88.6858 203.811C92.5651 199.796 99.2929 198.37 103.942 201.463C109.458 194.425 134.675 209.975 128.455 214.87C127.348 215.741 125.657 214.639 124.252 214.532C122.847 214.425 121.273 214.672 120.381 215.761C119.215 217.184 119.723 219.303 119.243 221.078C119.044 221.702 118.723 222.281 118.3 222.781C117.876 223.281 117.357 223.693 116.773 223.992C116.19 224.29 115.552 224.471 114.899 224.523C114.245 224.574 113.587 224.496 112.963 224.293C111.802 223.721 110.71 223.019 109.707 222.201C108.629 221.484 107.268 220.955 106.055 221.407C104.456 222.003 103.889 223.958 103.744 225.656C103.5 227.35 103.146 229.027 102.684 230.675Z" fill="#2F2E41"/>
<path opacity="0.2" d="M106.93 279.344L109.89 315.572L125.434 353.28L102.489 316.312L106.93 279.344Z" fill="black"/>
<path d="M298.15 477.734L135.929 381.597C133.146 379.944 131.133 377.255 130.333 374.122C129.532 370.988 130.008 367.665 131.657 364.881L207.245 237.608C208.458 235.572 210.43 234.099 212.728 233.512C215.026 232.926 217.464 233.275 219.505 234.481L387.21 333.868C389.284 335.1 390.784 337.104 391.381 339.439C391.978 341.775 391.623 344.252 390.394 346.326L314.884 473.466C313.229 476.246 310.537 478.257 307.4 479.057C304.263 479.857 300.936 479.381 298.15 477.734Z" fill="#170F5A" fill-opacity="0.89"/>
<path d="M152.345 377.141L294.196 461.206C296.897 462.807 300.124 463.27 303.167 462.494C306.211 461.718 308.821 459.766 310.423 457.068L368.411 359.431C370.013 356.732 370.477 353.508 369.7 350.469C368.924 347.429 366.97 344.821 364.268 343.221L222.418 259.156C219.716 257.555 216.489 257.092 213.446 257.868C210.403 258.644 207.793 260.596 206.19 263.294L148.203 360.931C146.6 363.63 146.136 366.854 146.913 369.893C147.69 372.933 149.644 375.541 152.345 377.141Z" fill="#171054"/>
<path d="M390.683 345.507L207.454 236.921L210.627 231.579C212.288 228.79 214.989 226.774 218.137 225.972C221.285 225.171 224.623 225.649 227.418 227.303L389.564 323.394C392.356 325.052 394.377 327.749 395.181 330.893C395.986 334.037 395.509 337.372 393.855 340.165L390.683 345.507Z" fill="#271F70"/>
<path d="M231.314 243.524C233.065 243.524 234.484 242.106 234.484 240.357C234.484 238.608 233.065 237.19 231.314 237.19C229.563 237.19 228.143 238.608 228.143 240.357C228.143 242.106 229.563 243.524 231.314 243.524Z" fill="white"/>
<path d="M241.664 249.658C243.415 249.658 244.834 248.24 244.834 246.491C244.834 244.742 243.415 243.324 241.664 243.324C239.913 243.324 238.493 244.742 238.493 246.491C238.493 248.24 239.913 249.658 241.664 249.658Z" fill="white"/>
<path d="M252.013 255.791C253.764 255.791 255.184 254.373 255.184 252.624C255.184 250.875 253.764 249.457 252.013 249.457C250.262 249.457 248.843 250.875 248.843 252.624C248.843 254.373 250.262 255.791 252.013 255.791Z" fill="white"/>
<path d="M349.914 364.043L349.708 364.39L351.794 365.626L352 365.279L349.914 364.043Z" fill="#E6E6E6"/>
<path d="M345.495 361.893L341.282 359.396L341.488 359.049L345.701 361.546L345.495 361.893ZM337.069 356.899L332.856 354.403L333.062 354.055L337.275 356.552L337.069 356.899ZM328.643 351.906L324.43 349.409L324.636 349.062L328.849 351.559L328.643 351.906ZM320.216 346.912L316.003 344.415L316.209 344.068L320.423 346.565L320.216 346.912ZM311.79 341.919L307.577 339.422L307.783 339.074L311.996 341.571L311.79 341.919ZM303.364 336.925L299.151 334.428L299.357 334.081L303.57 336.578L303.364 336.925ZM294.938 331.931L290.724 329.434L290.931 329.087L295.144 331.584L294.938 331.931ZM286.511 326.938L282.298 324.441L282.504 324.094L286.718 326.59L286.511 326.938ZM278.085 321.944L273.872 319.447L274.078 319.1L278.291 321.597L278.085 321.944ZM269.659 316.95L265.446 314.454L265.652 314.106L269.865 316.603L269.659 316.95ZM261.232 311.957L257.019 309.46L257.226 309.113L261.439 311.61L261.232 311.957ZM252.806 306.963L248.593 304.466L248.799 304.119L253.012 306.616L252.806 306.963ZM244.38 301.97L240.167 299.473L240.373 299.125L244.586 301.622L244.38 301.97ZM235.954 296.976L231.74 294.479L231.947 294.132L236.16 296.629L235.954 296.976ZM227.527 291.982L223.314 289.485L223.521 289.138L227.734 291.635L227.527 291.982ZM219.101 286.989L214.888 284.492L215.094 284.145L219.307 286.641L219.101 286.989Z" fill="#E6E6E6"/>
<path d="M208.796 280.412L208.589 280.759L210.675 281.995L210.881 281.648L208.796 280.412Z" fill="#E6E6E6"/>
<path d="M262.263 355.753L262.057 356.101L264.142 357.337L264.348 356.989L262.263 355.753Z" fill="#E6E6E6"/>
<path d="M257.906 353.641L253.756 351.181L253.962 350.834L258.112 353.294L257.906 353.641ZM249.605 348.721L245.455 346.262L245.661 345.915L249.811 348.374L249.605 348.721ZM241.304 343.802L237.153 341.342L237.36 340.995L241.51 343.455L241.304 343.802ZM233.003 338.883L228.852 336.423L229.059 336.076L233.209 338.535L233.003 338.883ZM224.702 333.963L220.551 331.503L220.757 331.156L224.908 333.616L224.702 333.963ZM216.401 329.044L212.25 326.584L212.456 326.237L216.607 328.696L216.401 329.044ZM208.099 324.124L203.949 321.664L204.155 321.317L208.306 323.777L208.099 324.124ZM199.798 319.205L195.648 316.745L195.854 316.398L200.005 318.857L199.798 319.205Z" fill="#E6E6E6"/>
<path d="M189.618 312.702L189.412 313.049L191.497 314.285L191.704 313.938L189.618 312.702Z" fill="#E6E6E6"/>
<path d="M212.523 346.93L212.316 347.277L214.402 348.513L214.608 348.166L212.523 346.93Z" fill="#E6E6E6"/>
<path d="M208.046 344.747L203.776 342.216L203.982 341.869L208.252 344.399L208.046 344.747ZM199.505 339.685L195.235 337.155L195.441 336.807L199.712 339.338L199.505 339.685ZM190.965 334.624L186.694 332.093L186.901 331.746L191.171 334.277L190.965 334.624Z" fill="#E6E6E6"/>
<path d="M180.545 327.979L180.339 328.327L182.424 329.562L182.63 329.215L180.545 327.979Z" fill="#E6E6E6"/>
<path d="M197.039 373.001L196.833 373.348L198.918 374.584L199.124 374.237L197.039 373.001Z" fill="#E6E6E6"/>
<path d="M192.562 370.818L188.292 368.287L188.498 367.94L192.768 370.47L192.562 370.818ZM184.022 365.756L179.751 363.225L179.958 362.878L184.228 365.409L184.022 365.756ZM175.481 360.695L171.211 358.164L171.417 357.817L175.687 360.348L175.481 360.695Z" fill="#E6E6E6"/>
<path d="M165.061 354.05L164.855 354.397L166.94 355.633L167.147 355.286L165.061 354.05Z" fill="#E6E6E6"/>
<path d="M176.455 361.632L296.664 432.871C299.576 434.593 303.053 435.09 306.333 434.254C309.612 433.417 312.425 431.316 314.155 428.411L349.278 369.272L349.036 369.138L269.347 389.457L263.034 364.767C262.919 364.31 262.699 363.887 262.393 363.529C262.086 363.172 261.701 362.89 261.267 362.706C260.604 362.439 259.871 362.398 259.182 362.589L217.421 373.236L212.805 355.184C212.705 354.795 212.53 354.428 212.289 354.106C212.048 353.785 211.745 353.513 211.399 353.308C211.053 353.103 210.67 352.968 210.271 352.911C209.873 352.854 209.467 352.875 209.077 352.975C208.795 353.05 181.621 360.242 176.455 361.632Z" fill="#464195"/>
<path d="M175.736 361.675L175.636 361.284L209.054 352.762C209.937 352.538 210.872 352.673 211.656 353.137C212.439 353.601 213.006 354.357 213.232 355.238L217.755 372.935L259.283 362.345C260.166 362.121 261.102 362.255 261.885 362.72C262.668 363.184 263.235 363.94 263.462 364.821L269.681 389.156L349.785 368.729L349.885 369.12L269.389 389.647L263.07 364.921C262.972 364.535 262.798 364.173 262.56 363.855C262.321 363.537 262.022 363.268 261.68 363.066C261.337 362.863 260.958 362.729 260.564 362.673C260.17 362.616 259.769 362.638 259.383 362.736L217.463 373.426L212.841 355.338C212.641 354.561 212.141 353.894 211.449 353.484C210.758 353.075 209.933 352.956 209.154 353.153L175.736 361.675Z" fill="#3F3D56"/>
<path d="M214.505 351.166C216.067 351.166 217.334 349.9 217.334 348.34C217.334 346.779 216.067 345.514 214.505 345.514C212.942 345.514 211.676 346.779 211.676 348.34C211.676 349.9 212.942 351.166 214.505 351.166Z" fill="#3F3D56"/>
<path d="M264.245 359.989C265.808 359.989 267.074 358.724 267.074 357.163C267.074 355.602 265.808 354.337 264.245 354.337C262.683 354.337 261.416 355.602 261.416 357.163C261.416 358.724 262.683 359.989 264.245 359.989Z" fill="#3F3D56"/>
<path d="M350.902 366.795C352.465 366.795 353.731 365.529 353.731 363.969C353.731 362.408 352.465 361.143 350.902 361.143C349.34 361.143 348.073 362.408 348.073 363.969C348.073 365.529 349.34 366.795 350.902 366.795Z" fill="#3F3D56"/>
<path d="M129.068 367.321C128.22 366.641 127.531 365.783 127.051 364.808C126.57 363.832 126.309 362.764 126.287 361.677C126.264 360.591 126.481 359.512 126.921 358.518C127.361 357.524 128.014 356.638 128.833 355.923L118.931 331.424L132.403 334.142L139.741 356.99C140.852 358.445 141.39 360.257 141.253 362.082C141.116 363.906 140.314 365.618 138.999 366.891C137.683 368.165 135.945 368.912 134.115 368.991C132.285 369.07 130.489 368.476 129.068 367.321Z" fill="#FFB8B8"/>
<path d="M128.904 356.707C128.174 356.759 127.447 356.57 126.835 356.17C126.223 355.769 125.76 355.178 125.517 354.489L109.487 308.857L106.061 263.332C105.967 262.08 106.123 260.821 106.52 259.628C106.917 258.436 107.547 257.335 108.374 256.389C109.201 255.442 110.208 254.669 111.337 254.115C112.465 253.561 113.693 253.236 114.948 253.16C116.204 253.084 117.462 253.258 118.649 253.672C119.836 254.086 120.93 254.732 121.865 255.572C122.8 256.411 123.559 257.429 124.097 258.564C124.636 259.699 124.943 260.93 125 262.185L126.918 303.761L139.499 349.764C139.716 350.571 139.622 351.43 139.236 352.171C138.851 352.913 138.201 353.484 137.416 353.771L129.789 356.516C129.504 356.619 129.206 356.683 128.904 356.707Z" fill="#33298A"/>
<path d="M426.123 316.274C425.824 316.143 425.546 315.969 425.297 315.759L410.389 303.215C410.031 302.913 409.739 302.541 409.532 302.121C409.325 301.701 409.208 301.243 409.187 300.775C409.167 300.307 409.244 299.841 409.413 299.404C409.583 298.968 409.841 298.571 410.171 298.24L423.699 284.671C424.267 284.096 425.023 283.745 425.829 283.681C426.635 283.617 427.437 283.844 428.089 284.322L438.319 291.742C438.957 292.206 439.413 292.877 439.609 293.641C439.805 294.405 439.727 295.213 439.39 295.926L430.54 314.618C430.164 315.413 429.492 316.031 428.667 316.34C427.843 316.649 426.93 316.625 426.123 316.274Z" fill="#6C63FF"/>
<path d="M484.267 493.692L475.364 496.112L461.783 462.958L474.924 459.387L484.267 493.692Z" fill="#FFB8B8"/>
<path d="M468.133 495.348L485.483 490.632L488.425 501.432L460.442 509.037L460.394 508.858C459.626 506.041 460.011 503.035 461.462 500.502C462.914 497.968 465.313 496.114 468.133 495.348Z" fill="#2F2E41"/>
<path d="M408.153 500.478L398.926 500.478L394.536 464.926L408.155 464.926L408.153 500.478Z" fill="#FFB8B8"/>
<path d="M392.15 497.844H410.131V509.036H381.131V508.851C381.131 505.932 382.292 503.132 384.358 501.068C386.425 499.004 389.227 497.844 392.15 497.844Z" fill="#2F2E41"/>
<path d="M422.053 265.746C432.263 265.746 440.539 257.479 440.539 247.281C440.539 237.082 432.263 228.815 422.053 228.815C411.844 228.815 403.568 237.082 403.568 247.281C403.568 257.479 411.844 265.746 422.053 265.746Z" fill="#FFB8B8"/>
<path d="M407.589 475.13H394.979C394.117 475.128 393.288 474.799 392.66 474.21C392.032 473.62 391.651 472.814 391.596 471.955C387.838 412.29 390.036 369.49 398.512 337.264C398.549 337.123 398.595 336.985 398.649 336.849L399.325 335.163C399.574 334.534 400.008 333.994 400.569 333.615C401.13 333.235 401.792 333.034 402.469 333.036H428.399C428.888 333.035 429.371 333.141 429.815 333.345C430.259 333.549 430.653 333.846 430.971 334.217L434.493 338.322C434.561 338.402 434.626 338.484 434.688 338.57C450.569 360.692 464.801 403.943 481.285 462.077C481.528 462.922 481.432 463.828 481.016 464.603C480.6 465.378 479.897 465.96 479.058 466.225L465.507 470.556C464.729 470.801 463.889 470.759 463.14 470.437C462.39 470.115 461.782 469.535 461.424 468.803L426.259 395.946C426.013 395.437 425.61 395.021 425.108 394.758C424.607 394.496 424.034 394.402 423.475 394.491C422.916 394.579 422.401 394.844 422.004 395.248C421.608 395.652 421.353 396.173 421.276 396.733L410.944 472.205C410.831 473.014 410.429 473.755 409.813 474.293C409.196 474.83 408.407 475.127 407.589 475.13Z" fill="#2F2E41"/>
<path d="M433.071 338.622C433.036 338.622 433 338.622 432.965 338.621L401.468 337.628C400.658 337.605 399.884 337.292 399.286 336.746C398.688 336.201 398.306 335.458 398.21 334.655C394.218 302.371 405.168 293.722 410.061 291.502C410.47 291.318 410.824 291.031 411.09 290.67C411.355 290.308 411.522 289.885 411.575 289.44L411.999 286.056C412.071 285.458 412.302 284.891 412.668 284.413C420.34 274.485 430.816 271.306 436.378 270.295C436.829 270.214 437.292 270.225 437.738 270.329C438.185 270.432 438.606 270.625 438.976 270.895C439.345 271.166 439.656 271.509 439.889 271.903C440.122 272.297 440.272 272.735 440.33 273.189L440.965 278.048C441.029 278.511 441.214 278.95 441.501 279.32C457.502 300.214 439.677 331.065 435.941 337.037C435.636 337.522 435.212 337.922 434.71 338.199C434.209 338.476 433.645 338.622 433.071 338.622Z" fill="#3D3487"/>
<path d="M442.392 235.796C441.019 231.639 440.006 227.371 439.363 223.041C439.161 220.519 439.807 217.807 441.645 216.067C445.085 212.811 450.93 214.362 454.527 217.443C459.726 221.896 462.319 228.739 463.299 235.51C464.279 242.28 463.848 249.165 464.084 256.002C464.32 262.839 465.299 269.869 468.788 275.756C472.277 281.642 478.752 286.16 485.582 285.659C488.339 285.511 490.957 284.402 492.981 282.527C493.975 281.583 494.728 280.414 495.176 279.119C495.624 277.825 495.755 276.441 495.558 275.085C499.049 281.33 497.279 289.792 492.072 294.701C486.865 299.61 478.856 300.997 472.011 298.902C465.165 296.806 459.531 291.551 456.166 285.238C452.801 278.926 451.568 271.633 451.747 264.484C451.884 258.993 452.818 253.458 451.889 248.045C450.961 242.632 447.569 237.135 442.221 235.861L442.392 235.796Z" fill="#2F2E41"/>
<path d="M418.376 242.913C417.464 245.956 413.977 247.832 410.839 247.317C409.278 246.993 407.833 246.252 406.658 245.174C405.484 244.095 404.624 242.719 404.169 241.192C403.307 238.097 403.66 234.791 405.155 231.947C405.979 230.243 407.132 228.718 408.547 227.459C409.962 226.2 411.611 225.232 413.401 224.611C415.201 224.015 417.118 223.86 418.991 224.159C420.863 224.457 422.636 225.201 424.161 226.328C424.323 225.644 424.665 225.016 425.151 224.509C425.637 224.001 426.249 223.632 426.926 223.439C428.288 223.073 429.725 223.094 431.075 223.5C434.967 224.608 438.339 227.059 440.592 230.416C442.816 233.763 444.287 237.552 444.905 241.522C445.966 247.475 445.575 253.98 442.228 259.019C438.88 264.057 432.033 267.032 426.477 264.63C426.131 262.861 426.951 261.08 427.901 259.547C428.85 258.014 429.975 256.521 430.34 254.755C430.705 252.99 430.006 250.831 428.296 250.254C426.838 249.761 425.283 250.559 423.747 250.655C422.874 250.71 422.003 250.535 421.219 250.147C420.436 249.759 419.769 249.172 419.284 248.445C418.8 247.718 418.515 246.877 418.459 246.005C418.403 245.134 418.577 244.263 418.964 243.48L418.376 242.913Z" fill="#2F2E41"/>
<path d="M371.526 318.984C371.832 319.266 372.115 319.572 372.373 319.899L409.586 317.006L413.509 308.84L426.621 313.145L419.529 332.513L372.653 329.37C371.727 330.678 370.424 331.674 368.918 332.225C367.412 332.775 365.773 332.855 364.22 332.453C362.668 332.05 361.274 331.186 360.226 329.973C359.177 328.761 358.523 327.258 358.35 325.665C358.177 324.072 358.494 322.464 359.258 321.055C360.022 319.646 361.197 318.503 362.628 317.778C364.058 317.053 365.676 316.78 367.265 316.995C368.855 317.21 370.341 317.904 371.526 318.984Z" fill="#FFB8B8"/>
<path d="M426.419 326.045C426.105 325.956 425.806 325.822 425.53 325.648L409.05 315.25C408.654 315 408.314 314.67 408.052 314.283C407.79 313.895 407.611 313.457 407.527 312.996C407.443 312.536 407.456 312.063 407.564 311.608C407.672 311.152 407.874 310.724 408.156 310.351L419.706 295.067C420.191 294.421 420.892 293.97 421.681 293.796C422.471 293.623 423.296 293.739 424.007 294.124L435.154 300.082C435.85 300.455 436.394 301.058 436.692 301.788C436.99 302.518 437.024 303.329 436.787 304.081L430.569 323.803C430.305 324.642 429.723 325.346 428.949 325.764C428.174 326.182 427.267 326.283 426.419 326.045Z" fill="#3D3487"/>
</g>
<defs>
<clipPath id="clip0_7_2">
<rect width="564" height="510" fill="white"/>
</clipPath>
</defs>
</svg>

                      </div>
                     <img src="/img/StarsME.svg" className="headerAffiliateI" alt="affiliate_banner"/>
                      <img src="/img/StarsME.svg" className="headerAffiliate2" alt="affiliate_banner"/>
                 </div>
                 </div>
                 </div>


        {/*======  section3========*/}

        <div  className="fareWo" >
      <div className="him"> 
      <div className="Rtme ">
          <div className="Free">
            <div><p className="EarnP" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">How It works </p>
            <div className="divUnder"></div></div>
          
          </div>
          {/*======  section4 ========*/}

          
                  <div className="goods1">
                    <div className="goods2">
                    <div className="inviteFr goods liom" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">Get Started with Easiest Steps</div>
                    <p className="proF" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
Get accurate winning tips from professional betting tipsters across sports, forex and cryptocurrency.</p>
           <div className='betStor ghFi likl'>
                  
                    <div className='bettors wanNy' data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
                    <div className='bettorsDiv'>
                       <div className="ghFii13 cvcvcnc">
  
                        <img src="/img/light.png" alt=""  className="withTyb"/>
                        </div>
                        <p className='bettors1' >01</p>
                    </div>
                    <div className='AddTip ghFii1 amGet'>Find your tipster</div>
                    <p className='itWell ghFii12'>Explore thousands of exprienced tipsters with verified picks and statistics.</p>
                    </div>
                
                 
                    <div className='bettors wanNy' data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
                    <div className='bettorsDiv'>
                       <div className="ghFii13 cvcvcnc"><img src="/img/light1.png" alt="" className="withTyb"  /></div>
                        <p className='bettors1' >02</p>
                    </div>
                    <div className='AddTip ghFii1 amGet'>Get your tips in your dashboard</div>
                    <p className='itWell ghFii12'>Select your tipsters and subscribe to get betting and trading tips on your dashboard.</p>
                    </div>
               
               
                    <div className='bettors wanNy' data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
                    <div className='bettorsDiv'>
                       <div className="ghFii13 cvcvcnc"> <img src="/img/light3.png" alt="" className="withTyb"/></div>
                        <p className='bettors1' >03</p>
                    </div>
                    <div className='AddTip ghFii1 amGet'>Place a bet & win</div>
                    <p className='itWell ghFii12'>When you receive a tip, open your favourite bookmaker's website or trading platform and place the bet or trade.</p>
                    </div>
               
            </div>
            </div>
            <div className="goods3  goods4"></div>
                 <div className="goods3 goods5"></div>
            </div>
            
   
          {/* secton5   */}

        

        {/* <div className="divRatT">
           <div className="teleCom turu">
               
               <div className="commT bg-yellow-box" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
                   <div className="FreeComm">Cryptocurrency Trading</div>
                   <div style={{padding:"0 40px"}}><div style={{width:"100%",borderBottom:"1px solid rgb(99 91 146)",margin:"0 auto 10px auto"}}></div></div>
                   <img src="/img/crypto.png" alt="not found" className="ballSport1 ffoo"/>
                   <img src="/img/crypto1.png" alt="not found" className="ballSport ffoo"/>
                   <p className="Saving">Earn</p>
                   <p className="perceT">65 - 100%</p>
                   <p className="dailyOn">Profits</p>
                   <p className="AirTime">Monthly</p>
                   <div className="successRate100">
                       <p className="RateSuccess">85% Success Rate</p>
                   </div>
               </div>
               <div className="commT dese bg-blue-box" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
                   <div className="FreeComm">Binary Option Trading</div>
                   <div style={{padding:"0 40px"}}><div style={{width:"100%",borderBottom:"1px solid rgb(99 91 146)",margin:"0 auto 10px auto"}}></div></div>
                   <img src="/img/binary.png" alt="not found" className="ballSport1 ffoo"/>
                   <img src="/img/binary1.png" alt="not found" className="ballSport ffoo"/>
                   <p className="Saving">Earn</p>
                   <p className="perceT">50-100%</p>
                   <p className="dailyOn">Profits</p>
                   <p className="AirTime">Monthly</p>
                  <div className="successRate100"><p className="RateSuccess">65% Success Rate</p></div>
               </div>
               <img src="/img/undrawWorld1.svg" alt="" className="papag3 nub" />
                <img src="/img/undrawWorld1.svg" alt="" className="papag4 nub1" />
           </div>
           </div> */}

           
          
           </div>

           <div className="glow glowLeft"></div>
            <div className="glow glowRight"></div>
           </div> 

           {/*  */}
           </div>
{/* <div >
   <div className="limba">
<div className="gos">
  <div className="gos7">
    <div className="gos2"><img src="/img/earnar.PNG" alt="" className="gos5" /></div>
     <div className="gos3">
      <div className="limba2">
      <div className="ET">Wegotips Token</div>
      <p className="gos4"><span className="goemJK">$ENAR</span> allows you to pay for services within the platform with ease or leverage on the ever increasing price for arbitrage purposes.</p>
        <div className="earnarC1"><button  className="earnarC" >Buy Enar</button></div>
      </div></div>
  </div>
</div>
</div>
</div> */}


         <div style={{position:"relative"}} className="paddingTip" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
        

           <div className="Rtme topPL">
               <div className="loud" style={{color: "#d5b405"}}>Top Tipsters</div>
               <div className="ye"></div>
               <div  className="GetP">See our professional  tipsters across all markets. Sports, Crypto, & Forex.</div>
               <div >
               <div className="faith" >
               <div className="Forex1">
                   <div className={sports==="Forex"?"Forex12 pin":"Forex12"} onClick={forf}>Forex</div>
                   <div className={sports === "sports"?"Forex12 pin":"Forex12"} onClick={Sports1}>Sports</div>
                   
                   <div className={sports === "cryptocurrency"?"Forex12 pin":"Forex12"} onClick={crypto}>Crypto</div>
                   <div className={sports === "binary"?"Forex12 pin":"Forex12"} onClick={bina}>Binary</div>
               </div>
               </div>
               </div>

           {sports === "sports" ? 
             <div style={{padding:"0 10px"}} >
               <Carousel
  swipeable={true}
  draggable={false}
  showDots={true}
  responsive={responsive1}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  arrows={false} 
   renderButtonGroupOutside={true}
    customButtonGroup={<ButtonGroup />}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlay={true}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={false}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
   className="king"
 
>
               { (loading) ?  sportItem.map((app,id)=>(
              //  <div className="sport2 cappin yellowD" key={id}>
              //          <div className="divS"><span className="Nog rere">Game No: </span>SP-{date}/{app.game_no}</div>
              //          <p className="dailyP21">Daily Profit</p>
              //          <p className="Dyc">{app.profit}%</p>
              //          <p className="addErn">odd:{app.odd}</p>
              //          <div className={app.action === "earned"?"EarnLoss":"EarnLoss losscolor"}>{app.action === "earned"?"Earned":"Loss"}</div>

              //      </div> 
                <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/sportsBall.jpg" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
            <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
       

          <a href="/view-profile" className="profileLink"><div className="houseRuMM WonB">View Profile</div></a>
        </div>
                       </div>
                   )): posts1.map((app,id)=>(
                  //  <div className="sport2 cappin yellowD" key={id}>
                  //      <div className="divS"><span className="Nog rere">Game No: </span>SP-{date}/{app.game_no}</div>
                  //      <p className="dailyP21">Daily Profit</p>
                  //      <p className="Dyc">{app.daily_profit}%</p>
                  //      <p className="addErn">odd:{app.odd}</p>
                  //      <div className={app.action === "earned"?"EarnLoss":"EarnLoss losscolor"}>{app.action === "earned"?"Earned":"Loss"}</div>

                  //  </div>
                    <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/sportsBall.jpg" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
            <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
        

          <a href="/view-profile" className="profileLink"><div className="houseRuMM WonB">View Profile</div></a>
        </div>
                       </div>
                   ))
  
                 }

               




       
</Carousel>
</div>
:""}


               { sports ==="Forex"? 
               <div style={{padding:"0 0px 0 0px"}}>
                       
                                 <Carousel
  swipeable={true}
  draggable={false}
  showDots={true}
  responsive={responsive1}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  arrows={false} 
   renderButtonGroupOutside={true}
    customButtonGroup={<ButtonGroup />}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlay={true}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
    partialVisible={true}
  containerClass="carousel-container"
  removeArrowOnDeviceType={false}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  className="king"
>


  {(loading) ? forexItem.map((app,id)=>(
                        // <div className="sport2 cappin yellowC " key={id}>
                        //     <div className="divS"><span className="Nog">Trade No: </span>FX-{date}/{app.r_trade_no}</div>

                        //     <div className="busyer">
                        //     <div className="pairs">
                        //         <p className="Usdj ">Pair: <span className="upcase">{app.r_pair}</span></p>
                        //         <p className="Usdj">Condition: <span className="lowcase">{app.r_condition}</span></p>
                        //     </div>
                        //    <div className="pairs">
                        //        <div>
                        //            <p className="Usdj">Entry point-{app.r_entry_point}</p>
                        //            <p className="Usdj">Exit point-{app.r_exit_point}</p>
                        //        </div>
                        //        <div className="Fitwem">
                        //            <p className="Usdj addpo">Take Profit</p>
                        //            <p className="Usdj addpo1" >{app.r_take_profit} pips</p>
                        //        </div>
                        //    </div>

                        //    </div>
                           
                          
                        //     <div className= {app.r_check === "earned"?"EarnLoss":"EarnLoss losscolor"}><FontAwesomeIcon icon={faCheckDouble} className="icon_brand_size"/>{app.r_check === "earned"?"Earned":"Loss"}</div>
     
                        // </div>
                        <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
            <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
        

          <a href="/view-profile" className="profileLink"><div className="houseRuMM WonB">View Profile</div></a>
        </div>
                       </div> )) :post.map((app,id)=>(
                        // <div className="sport2 cappin yellowC" key={id}>
                        //     <div className="divS"><span className="Nog">Trade No: </span>FX-{date}/{app.r_trade_no}</div>

                        //     <div className="busyer">
                        //     <div className="pairs">
                        //         <p className="Usdj ">Pair: <span className="upcase">{app.r_pair}</span></p>
                        //         <p className="Usdj">Condition: <span className="lowcase">{app.r_condition}</span></p>
                        //     </div>
                        //    <div className="pairs">
                        //        <div>
                        //            <p className="Usdj">Entry point-{app.r_entry_point}</p>
                        //            <p className="Usdj">Exit point-{app.r_exit_point}</p>
                        //        </div>
                        //        <div className="Fitwem">
                        //            <p className="Usdj addpo">Take Profit</p>
                        //            <p className="Usdj addpo1" >{app.r_take_profit} pips</p>
                        //        </div>
                        //    </div>

                        //    </div>
                           
                          
                        //     <div className= {app.r_check === "earned"?"EarnLoss":"EarnLoss losscolor"}><FontAwesomeIcon icon={faCheckDouble} className="icon_brand_size"/>{app.r_check === "earned"?"Earned":"Loss"}</div>
     
                        // </div>
                        <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
           <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
        

         <a href="/view-profile" className="profileLink"> <div className="houseRuMM WonB">View Profile</div></a>
        </div></div>
                        ))}

                        </Carousel>
                        </div>

                  
               :""}
             

               { sports === "cryptocurrency"? 
                 <div style={{padding:"0 10px"}}>
                 <Carousel
                 swipeable={true}
                 draggable={false}
                 showDots={true}
                 responsive={responsive1}
                 ssr={true} // means to render carousel on server-side.
                 infinite={true}
                 // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                 autoPlay={true}
                 autoPlaySpeed={3000}
                 keyBoardControl={true}
                 customTransition="all .5"
                 transitionDuration={500}
                 containerClass="carousel-container"
                 removeArrowOnDeviceType={["tablet", "mobile"]}
                 // deviceType={this.props.deviceType}
                 dotListClass="custom-dot-list-style"
                 itemClass="carousel-item-padding-40-px"
                  className="king"
               >

                   {(loading) ? cryptoItem.map((app,id)=>(
                    // <div className="sport2 cappin yellowA" key={id}>
                    //     <div className="divS"><span className="Nog Nog4">Trade No: </span>CT-{date}/{app.c_trade_no}</div>

                    //     <div className="busyer">
                    //     <div className="pairs">
                    //         <p className="Usdj">Pair:<span className="upcase">{app.c_pair}</span></p>
                    //         <p className="Usdj">Condition:<span className="lowcase">{app.c_condition}</span></p>
                    //     </div>
                    //    <div className="pairs">
                    //        <div>
                    //            <p className="Usdj">Entry point-{app.c_entry_point}</p>
                    //            <p className="Usdj">Exit point-{app.c_exit_point}</p>
                    //        </div>
                    //        <div className="Fitwem">
                    //            <p className="Usdj Nog4">Take Profit</p>
                    //            <p className="Usdj Nog4fade">{app.c_take_profit}%</p>
                    //        </div>
                    //    </div>

                    //    </div>
                       
                      
                    //     <div className={app.c_check === "earned"?"EarnLoss":"EarnLoss losscolor"}>{app.c_check === "earned"?"Earned":"Loss"}</div>
 
                    // </div>
                      <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
             <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
          

         <a href="/view-profile"  className="profileLink"> <div className="houseRuMM  WonB">View Profile</div> </a>
        </div>
                       </div>
                    )):post3.map((app,id)=>(
                    // <div className="sport2 cappin yellowA" key={id}>
                    //     <div className="divS"><span className="Nog Nog4">Trade No: </span>CT-{date}/{app.c_trade_no}</div>

                    //     <div className="busyer">
                    //     <div className="pairs">
                    //         <p className="Usdj">Pair:<span className="upcase">{app.c_pair}</span></p>
                    //         <p className="Usdj">Condition:<span className="lowcase">{app.c_condition}</span></p>
                    //     </div>
                    //    <div className="pairs">
                    //        <div>
                    //            <p className="Usdj">Entry point-{app.c_entry_point}</p>
                    //            <p className="Usdj">Exit point-{app.c_exit_point}</p>
                    //        </div>
                    //        <div className="Fitwem">
                    //            <p className="Usdj Nog4">Take Profit</p>
                    //            <p className="Usdj Nog4fade">{app.c_take_profit}%</p>
                    //        </div>
                    //    </div>

                    //    </div>
                       
                      
                    //     <div className={app.c_check === "earned"?"EarnLoss":"EarnLos losscolor"}>{app.c_check === "earned"?"Earned":"Loss"}</div>
 
                    // </div>
                      <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
          <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
          

          <a href="/view-profile"  className="profileLink"><div className="houseRuMM  WonB">View Profile</div></a>
        </div>
                       </div>
                    ))}
                    </Carousel>
                    </div>
                    :""}

 { sports=== "binary"? 
            <div style={{padding:"0 10px"}}>
                   <Carousel
                   swipeable={true}
                   draggable={false}
                   showDots={true}
                   responsive={responsive1}
                   ssr={true} // means to render carousel on server-side.
                   infinite={true}
                   // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                   autoPlay={true}
                   autoPlaySpeed={3000}
                   keyBoardControl={true}
                   customTransition="all .5"
                   transitionDuration={500}
                   containerClass="carousel-container"
                   removeArrowOnDeviceType={["tablet", "mobile"]}
                   // deviceType={this.props.deviceType}
                   dotListClass="custom-dot-list-style"
                   itemClass="carousel-item-padding-40-px"
                    className="king"
                 >
                 

                {(loading)? binaryItem.map((app,id)=>(
                 
                    // <div className="sport2 cappin yellowB" key={id}>
                    //     <div className="divS"><span className="Nog Nog5">Trade No: </span>BN-{date}/{app.b_trade_no}</div>

                    //     <div className="busyer">
                    //     <div className="pairs">
                    //         <p className="Usdj">Pair: {app.b_pair}</p>
                    //         <p className="Usdj">Condition: {app.b_condition}</p>
                    //     </div>
                    //    <div className="pairs">
                    //        <div>
                    //            <p className="Usdj">TIME : {app.b_expire_time}</p>
                             
                    //        </div>
                    //        <div className="Fitwem">
                    //            <p className="Usdj Nog5">Take Profit</p>
                    //            <p className="Usdj Nog5fade">{app.b_profit}%</p>
                    //        </div>
                    //    </div>

                    //    </div>
                       
                      
                    //     <div className={app.b_check === "earned"?"EarnLoss":"EarnLoss losscolor"}> {app.b_check === "earned"?"Earned":"Loss"}</div>
 
                    // </div>
                      <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
           <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
          

         <a href="/view-profile"  className="profileLink "><div className="houseRuMM WonB">View Profile</div></a>
        </div>
                       </div>
                    )):post4.map((app,id)=>(
                    // <div className="sport2 cappin yellowB" key={id}>
                    //     <div className="divS"><span className="Nog Nog5">Trade No: </span>BN-{date}/{app.b_trade_no}</div>

                    //     <div className="busyer">
                    //     <div className="pairs">
                    //         <p className="Usdj">Pair: {app.b_pair}</p>
                    //         <p className="Usdj">Condition: {app.b_condition}</p>
                    //     </div>
                    //    <div className="pairs">
                    //        <div>
                    //            <p className="Usdj">TIME : {app.b_expire_time}</p>
                             
                    //        </div>
                    //        <div className="Fitwem">
                    //            <p className="Usdj Nog5">Take Profit</p>
                    //            <p className="Usdj Nog5fade">{app.b_profit}%</p>
                    //        </div>
                    //    </div>

                    //    </div>
                       
                      
                    //     <div className={app.b_check === "earned"?"EarnLoss":"EarnLoss losscolor"}> {app.b_check === "earned"?"Earned":"Loss"}</div>
 
                    // </div>
                      <div style={{padding:"10px 0 25px 0"}}>
                          <div className="cardProfileDiv" key={id} style={{border:"1px solid #3c2f8b"}}>
          <div className="catImgDivProfile"><img src="/img/kucoin.png" alt="" style={{width:"100%",height:"100%",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}/></div>
           <div className="imgcardPic"><img src="/img/create.png" alt="" className="catImgDivProfile1"/></div>
          <div className="donKing">Samuel453</div>
          <div className="ipconFig">
            <div>
              <div className="imgWay">
                <div><img src="/img/followers.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp" >Followers</p>
                </div>
              </div>
            </div>
             <div>
               <div className="imgWay" >
                <div><img src="/img/following.png" alt=""/></div>
                <div>
                  <p className="followerp1">24k</p>
                  <p className="followerp">Following</p>
                </div>
              </div>
             </div>
          </div>
            <div className="typing">
            <div className='ROILING'>
            <div className="pork1">+4,345</div>
            <p className='ROIL'>ROI</p>
            </div>
            <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Profit</p>
            </div>
             <div className='ROILING'>
            <div className="pork">+2,434</div>
            <p className='ROIL'>Win Rate</p>
            </div>
          </div>
         

         <a href="/view-profile"  className="profileLink"> <div className="houseRuMM  WonB">View Profile</div></a>
        </div>
                       </div>
                  
                    ))}
                  
                    </Carousel>
                    </div>
               :""} 




      
           </div>
         
           </div>

           {/* end section6 */}


           {/*section7 */}

           <div className="home5">

              {/* <div class="sky"> */}
    
    {/* <div className="stars"></div>
    <div className="stars1"></div>
    <div className="stars2"></div> */}
   

            <div className="containerT">

               <div className="youpin carU "data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear" >

              
                 <div className="topPrediction">Top Predictions</div>
                  <div  className="GetP">Discover the top predictions predicted by our expert tipsters.</div>
                 <div style={{padding: "1px  10px"}}>
              <div className="UponT" >
                    <div  className="market">
               <div className="faith" >
               <div className="Forex1">
                   <div className={prediction==="forexPre"?"Forex12 pin":"Forex12"} onClick={()=>setPrediction("forexPre")}>Forex</div>
                   <div className={prediction === "sportsPre"?"Forex12 pin":"Forex12"} onClick={()=>setPrediction("sportsPre")}>Sports</div>
                   
                   <div className={prediction === "cryptoPre"?"Forex12 pin":"Forex12"} onClick={()=>setPrediction("cryptoPre")}>Crypto</div>
                   <div className={prediction === "binaryPre"?"Forex12 pin":"Forex12"} onClick={()=>setPrediction("binaryPre")}>Binary</div>
               </div>
               </div>
               </div>
                {/* <div className="sportPetNe">Sports Predictions</div> */}
               
               {prediction === "sportsPre" ? <>
           <div className="adaba"> 
       <div role="region" aria-labelledby="caption" tabindex="0">
  <table   style={{borderCollapse: "collapse"}}  className='linema '>
   
    <thead>
      <tr>
        <th style={{width:"100px"}}>Date</th>
        <th style={{width:"70px"}}>Time</th>
         <th style={{width:"150px"}}>League</th>
        <th style={{width:"180px"}}>Team</th>
        <th style={{width:"180px"}}>Tips</th>
        <th style={{width:"60px"}}>Odd</th>
        <th style={{width:"80px"}}>Stake</th>
        <th style={{width:"80px"}}>Results</th>
           <th style={{width:"80px"}}>Tipster</th>

                
      </tr>
    </thead>
    <tbody>
       
       
     
        <tr  className="ADDTimeDbg " >
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>13:00pm</td>
          <td>Champions league</td>
        <td>Chealse vs AsRoma</td>
        <td>x2</td>
        <td>1.72</td>
        <td>10,000</td>
         <td><img src="/img/good.png" alt="" /></td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
    
        
      </tr>
      <tr  className="ADDTimeDbg ">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>13:00am</td>
          <td>France League1</td>
        <td>PSG Vs Strasbourg</td>
        <td>1x</td>
        <td>1.56</td>
        <td>5,000</td>
         <td><img src="/img/good.png" alt="" /></td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
      </tr>

            <tr  className="ADDTimeDbg ">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>13:00am</td>
          <td>France League1</td>
        <td>PSG Vs Strasbourg</td>
        <td>1x</td>
        <td>1.56</td>
        <td>5,000</td>
         <td><img src="/img/good.png" alt="" /></td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
      </tr>

            <tr  className="ADDTimeDbg ">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>13:00am</td>
          <td>France League1</td>
        <td>PSG Vs Strasbourg</td>
        <td>1x</td>
        <td>1.56</td>
        <td>5,000</td>
         <td><img src="/img/good.png" alt="" /></td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
      </tr>
            <tr  className="ADDTimeDbg ">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>13:00am</td>
          <td>France League1</td>
        <td>PSG Vs Strasbourg</td>
        <td>1x</td>
        <td>1.56</td>
        <td>5,000</td>
         <td><img src="/img/good.png" alt="" /></td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
      </tr>
  
        
      
   
     
    </tbody>
  </table>
</div>
</div> 
</> :""}
      
       {prediction === "forexPre" ? <>
           <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}}>
   
    <thead>
      <tr>
        <th style={{width:"100px"}}>Date</th>
        <th style={{width:"100px"}}>Pair</th>
         <th style={{width:"100px"}}>Condition</th>
        <th style={{width:"80px"}}>Entry</th>
        <th style={{width:"80px"}}>Take Profit</th>
        <th style={{width:"80px"}}>Stop Loss</th>
         <th style={{width:"80px"}}>Result</th>
         <th style={{width:"80px"}}>Tipster</th>
        
                
      </tr>
    </thead>
    <tbody>
       
       
     
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>NGN/USD</td>
          <td>Long(buy)</td>
        <td>1.678787</td>
        <td>1.899765</td>
        <td>1.456378</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>CED/USD</td>
          <td>Short(buy)</td>
        <td>1.678787</td>
        <td>1.678787</td>
        <td>1.678787</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
         <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>CED/USD</td>
          <td>Short(buy)</td>
        <td>1.678787</td>
        <td>1.678787</td>
        <td>1.678787</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
         <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>CED/USD</td>
          <td>Short(buy)</td>
        <td>1.678787</td>
        <td>1.678787</td>
        <td>1.678787</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
         <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>CED/USD</td>
          <td>Short(buy)</td>
        <td>1.678787</td>
        <td>1.678787</td>
        <td>1.678787</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
  
        
      
   
     
    </tbody>
  </table>
</div>
</div>
</> : ""}

 {/* <div className="seeMoreDivLink"><button className="SeeMoreInfo">See More</button></div> */}

   {prediction === "cryptoPre" ? <>
 <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}} >
   
    <thead>
      <tr>
        <th style={{width:"100px"}} className="emeka">Date</th>
        <th style={{width:"100px"}}>Pair</th>
         <th style={{width:"100px"}}>Condition</th>
        <th style={{width:"80px"}}>Entry</th>
        <th style={{width:"80px"}}>Take Profit</th>
        <th style={{width:"80px"}}>Stop Loss</th>
          <th style={{width:"80px"}}>Result</th>
         
        <th style={{width:"80px"}}>Tipster</th>
        
                
      </tr>
    </thead>
    <tbody>
       
       
     
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>BTC/USD</td>
          <td>Long(buy)</td>
        <td>30000.05</td>
        <td>76000.00</td>
        <td>29500.00</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
        <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>BTC/USD</td>
          <td>Long(buy)</td>
        <td>30000.05</td>
        <td>76000.00</td>
        <td>29500.00</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
        <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>BTC/USD</td>
          <td>Long(buy)</td>
        <td>30000.05</td>
        <td>76000.00</td>
        <td>29500.00</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
        <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>BTC/USD</td>
          <td>Long(buy)</td>
        <td>30000.05</td>
        <td>76000.00</td>
        <td>29500.00</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>ETH/USD</td>
          <td>Long(buy)</td>
        <td>1750.05</td>
        <td>15000.00</td>
        <td>1650.00</td>
         <td><img src="/img/good.png" alt="" /></td>
        <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
  
        
      
   
     
    </tbody>
  </table>
</div>
</div>
</>  : "" }
    {/* <div className="seeMoreDivLink"><button className="SeeMoreInfo">See More</button></div> */}

 {prediction === "binaryPre" ? <>
 <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}} >
   
    <thead>
      <tr>
        <th style={{width:"80px"}}>Date</th>
        <th style={{width:"80px"}}>Pair</th>
         <th style={{width:"80px"}}>Condition</th>
          <th style={{width:"80px"}}>Result</th>
         <th style={{width:"80px"}}>Tipster</th>
       
                
      </tr>
    </thead>
    <tbody>
       
       
      
         <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Long(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
       
        
      </tr>
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Short(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
       
        
      </tr>
  <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Short(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
       
        
      </tr>
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Short(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
       
        
      </tr>
  <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Short(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
       
        
      </tr>
  
  <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className="ADDTimeDbg">09/11/2023</th>
        <td>GBP/USD</td>
          <td>Short(buy)</td>
          <td><img src="/img/good.png" alt="" /></td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
       
        
      </tr>
  
        
      
   
     
    </tbody>
  </table>
</div>
</div>
</> : "" }
 {/* <div className="seeMoreDivLink"><a href="/market-predictions"><button className="SeeMoreInfo">See More</button></a></div> */}

                </div>
                </div>

              
               </div>

             
{/* <div className="limba">
<div className="gos">
  <div className="gos1">
    <div className="gos2"><div className="jimk"><img src="/img/earnv.jpeg" alt="" className="gos5" /></div></div>
     <div className="gos3"><div className="limba2">
      <div className="ET">Earnars Token</div>
      <p className="gos4"><span className="goemJK">$ENAR</span> allows you to pay for services within the platform with ease or leverage on the ever increasing price for arbitrage purposes.</p></div></div>
  </div>
</div>
</div> */}




          <div className="youpin carU"data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear" >

              
                 <div className="topPrediction">Recent Tips From Tipsters</div>
                 <div style={{padding: "1px  10px"}}>
              <div className="UponT" >
                     <div className="market">
               <div className="faith" >
               <div className="Forex1">
                   <div className={currentPrediction ==="cuForexPre"?"Forex12 pin":"Forex12"} onClick={()=>setCurrentPrediction("cuForexPre")}>Forex</div>
                   <div className={currentPrediction === "cuSportsPre"?"Forex12 pin":"Forex12"} onClick={()=>setCurrentPrediction("cuSportsPre")}>Sports</div>
                   
                   <div className={currentPrediction === "cuCryptoPre"?"Forex12 pin":"Forex12"} onClick={()=>setCurrentPrediction("cuCryptoPre")}>Crypto</div>
                   <div className={currentPrediction === "cuBinaryPre"?"Forex12 pin":"Forex12"} onClick={()=>setCurrentPrediction("cuBinaryPre")}>Binary</div>
               </div>
               </div>
               </div>
                
             {currentPrediction  ===  "cuSportsPre" ? <> 
           <div className="adaba"> 
       <div role="region" aria-labelledby="caption" tabindex="0">
  <table   style={{borderCollapse: "collapse"}}  className='linema '>
   
    <thead>
      <tr>
        <th style={{width:"100px"}}>Date</th>
        
        <th style={{width:"80px"}}>Time</th>
         <th style={{width:"150px"}}>League</th>
        <th style={{width:"180px"}}>Team</th>
        <th style={{width:"80px"}}>Odd</th>
        <th style={{width:"80px"}}>Stake</th>
        <th style={{width:"80px"}}>Yeild</th>
        <th style={{width:"80px"}}>Tipster</th>
                
      </tr>
    </thead>
    <tbody>
       
       
      
       {tableContent.map((app,id)=>(
    
        <tr  className="ADDTimeDbg " key={id}>
        <th className=" ADDTimeDbg">09/11/2023</th>
        
        <td>13:00pm</td>
          <td>English</td>
        <td>Eng v Ban Asia Cup T20</td>
        <td>1.72</td>
        <td>+20.45</td>
        <td>+3%</td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
      </tr>
      ))} 
      
        
      
  
        
      
   
     
    </tbody>
  </table>
</div>
</div> 
</> : ""} 
 {/* <div className="seeMoreDivLink"><button className="SeeMoreInfo">See More</button></div> */}
          
           {currentPrediction  ===  "cuForexPre" ? <> 
           <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}}>
   
    <thead>
      <tr>
        <th style={{width:"100px"}}>Date</th>
       
         <th style={{width:"100px"}}>Condition</th>
        <th style={{width:"100px"}}>Entry</th>
        <th style={{width:"100px"}}>Take Profit</th>
        <th style={{width:"100px"}}>Stop Loss</th>
        <th style={{width:"100px"}}>Tipster</th>
        
        
                
      </tr>
    </thead>
    <tbody>
       
     {tableContent1.map((app,id)=>( 
      <tr  className="ADDTimeDbg ADDTimeDbg22" key={id}>
        <th className=" ADDTimeDbg">09/11/2023</th>
        
          <td>Long(buy)</td>
        <td>1.678787</td>
        <td>1.899765</td>
        <td>1.456378</td>
         <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
      ))} 
     
    </tbody>
  </table>
</div>
</div>
</> : ""}
 {/* <div className="seeMoreDivLink"><button className="SeeMoreInfo">See More</button></div> */}

 {currentPrediction  ===  "cuCryptoPre" ? <> 
 <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}} >
   
    <thead>
      <tr>
        <th style={{width:"100px"}} className="emeka">Date</th>
       
         <th style={{width:"100px"}}>Condition</th>
        <th style={{width:"100px"}}>Entry</th>
        <th style={{width:"100px"}}>Take Profit</th>
        <th style={{width:"100px"}}>Stop Loss</th>
        <th style={{width:"100px"}}>Tipster</th>

        
                
      </tr>
    </thead>
    <tbody>
       
        
     {tableContent2.map((app,id)=>( 
      <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
       
          <td>Long(buy)</td>
        <td>9.0615</td>
        <td>12.0500</td>
        <td>8.5000</td>
       
          <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
       
        
      </tr>
       ))} 
     
    </tbody>
  </table>
</div>
</div>
</> :""}
    {/* <div className="seeMoreDivLink"><button className="SeeMoreInfo">See More</button></div> */}

 {currentPrediction  ===  "cuBinaryPre" ? <> 
 <div className="adaba">
      <div role="region" aria-labelledby="caption" tabindex="0">
  <table className='linema' style={{borderCollapse: "collapse"}} >
   
    <thead>
      <tr>
        <th style={{width:"100px"}}>Date</th>
       
         <th style={{width:"100px"}}>Condition</th>

           <th style={{width:"70px"}}>Tipster</th>
       
                
      </tr>
    </thead>
    <tbody>
       
       
        {tableContent2.map((app,id)=>( 
         <tr  className="ADDTimeDbg ADDTimeDbg22">
        <th className=" ADDTimeDbg">09/11/2023</th>
       
          <td>Long(buy)</td>
           <td><a href="/view-profile"><img src="/img/create.png" alt="" className="tipImage"/></a></td>
        
       
        
      </tr>
          ))}
     
    </tbody>
  </table>
</div>
</div>
</> : ""}
 {/* <div className="seeMoreDivLink"><a href="/recent-predictions"><button className="SeeMoreInfo">See More</button></a></div> */}

                </div>
                </div>

              
               </div>



              


           <div className="Rtme" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
             <div style={{padding:"0px 10px"}}>
           <div  className="Great" >
             <div className="with">Partners With Great Benefits  </div>
            <div className="ye"></div>
           <div className="Make15">
               <div>
                   <div>
                       <p className="millionM"  style={{color: "#7b7ee2" }} >Make $1,000 on referral commission monthly </p>
                       {/* <p className="OnRef">On Referral Commission</p> */}
                        <div className="visitMe"><a href="/affiliate"><button className="visitMe3">join Affiliate</button></a></div>
                   </div>
               </div>
               <div>
                 <div className="yuri2">
                 <div className="yuri"></div>
                 <p className="Onref1">75%  Commission</p>
                 </div>
                  
                  <div className="yuri2">
                  <div className="yuri"></div>
                   <p className="Onref1">Daily payout</p>
                   </div>

                   <div className="yuri2">
                   <div className="yuri"></div>
                   <p className="Onref1">Instant withdrawal to Account</p>
                   </div>

                   <div className="yuri2">
                   <div className="yuri"></div>
                   <p className="Onref1">Residual Income For Life</p>
                   </div>
               </div>
           </div>
           <img src="/img/pgf.svg" alt="image"  className="emWike"/>
          
           </div>
           </div>
           </div>
               {/* </div> */}
               </div>

           </div>

              

               
               </div>

               {/*  */}


           <div data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
            <div className="Rtme">
             <div style={{padding:"0px 10px"}}>
           <div  className="Great great1" >
             <div className="with">Become A Tipster  </div>
            <div className="ye"></div>
           <div className="Make15">
               <div>
                   <div>
                       <p className="millionM ggmj"  style={{color: "#7b7ee2" }} >Join Tipsters across the world to give tip </p>
                       {/* <p className="OnRef">On Referral Commission</p> */}

                   </div>
               </div>
               <div>
                 <div className="yuri2">
                 <div className="getstart"><a href="/become-tipster" className="hmr">Get started</a></div>
                 </div>
                  
                  

                   

                  
               </div>
           </div>
         
          
           </div>
           </div>
           </div>
           </div>
    

          {/*======  section5 ========*/}

      <div className="WhatD">
         <div className="Rtme">
        <div className='TheyAre' data-aos="fade-down" data-aos-duration="3000"
             data-aos-easing="linear">They are talking about us</div>
          <div className='DonTake' data-aos="fade-down" data-aos-duration="3000"
             data-aos-easing="linear">Don't Take Our Word For It.</div>
          <div className='seeWhat' data-aos="fade-down" data-aos-duration="3000"
             data-aos-easing="linear">See what our customers have to say.Find out what our clients are saying below</div>
         {/* <div className="divUnder1"></div> */}
         <div className=" marginMu fadama " data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
         <Carousel
  swipeable={true}
  draggable={false}
  showDots={false}
  responsive={responsive2}
   arrows={false} 
   renderButtonGroupOutside={true}
    customButtonGroup={<ButtonGroup />}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlay={false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={false}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  
>
 <div style={{padding:"4px 10px"}}>      
<div className="earnSayPp">
  <p className="partnerShip">"Through Wegotips partnership program I made 250k in just two weeks by referring 20friends to the platform."</p>
  <div className="favourPath">
    <div><img src="/img/create.png" alt="" className="popopo"/></div>
     <div>
      <div className="amakaDiv">-Chukwubuike Amogu</div>
      <div className="country">Nigeria</div>
     </div>
  </div>
</div>
</div> 
 <div style={{padding:"4px 10px"}}>           
<div className="earnSayPp">
  <p className="partnerShip">"I made Over 1 Million in Less Than 30 Days by just following Wegotips.com prediction on Sports and Forex Trading.I would recommend wegotips to any body any time."</p>
  <div className="favourPath">
    <div><img src="/img/create.png" alt="" className="popopo"/></div>
     <div>
      <div className="amakaDiv">-Amaka Ngozi</div>
      <div className="country">Nigeria</div>
     </div>
  </div>
</div>
</div>
<div style={{padding:"4px 10px"}}>
<div className="earnSayPp">
  <p className="partnerShip">"Your company is truly upstanding and is behind its product 100%. Keep up the excellent work. Wegotips is the most valuable prediction portal i have ever come across. I love your system."</p>
  <div className="favourPath">
    <div><img src="/img/create.png" alt="" className="popopo"/></div>
     <div>
      <div className="amakaDiv">-Amaka Amogu</div>
      <div className="country">Nigeria</div>
     </div>
  </div>
</div>
</div>
         
          </Carousel>
        </div>





                   <div style={{padding:"0 10px"}}>
          <div id="Faqc" data-aos="fade-up" data-aos-duration="3000"
             data-aos-easing="linear">
            <div className="firstTom" data-aos="fade-down" data-aos-duration="3000"
             data-aos-easing="linear" style={{color:"#fff"}}>FAQ</div>
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop}>
                <span>I have never traded the forex or crypto market before. How do I Start?</span>{" "}
                <span className="IconSpace">
                {show? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show ? (
                  <div>
                    <div className="Qac"></div>
                <p className="ILOVEK">
                  {" "}
                  To start trading the forex or cryptocurrency market all you need to know is to understand the basics of trading like, how to Buy/Sell, How to set Stoploss and Takeprofit, How to spot support and Resistance. With this basic knowledge you can follow our Tipsters expert forecast and achieve success in the market.{" "}
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop1}>
                <span>I don't know how to bet on sports. How do I start?</span>
                <span className="IconSpace">
                {show1? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show1 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                 You don't need to understand much about sport betting to bet. Once you are registered with our recommended bookmaker, subscribe to any tipster membership and copy their coupon code to place your bet with the bookmaker specified by the tipster.
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop2}>
                <span> I don't Have an account with any forex platform. Can I get any recommendations? </span>
                <span className="IconSpace">
                  {show2? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show2 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                Click the market button on the header and go through forex section to see recommended brokers
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop3}>
                <span> How much does it cost to subscribe monthly? </span>
                <span className="IconSpace">
                  {show3? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show3 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                Purchasing a membership Subscription from any tipster is flexible. You can either buy daily, weekly or monthly subscription
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="homeChange">
              <div className="HowFarBro" onClick={drop4}>
                <span> How much does it cost to subscribe monthly? </span>
                <span className="IconSpace">
                  {show4? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show4 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                  It cost only $50 or ₦25,000 to subscribe monthly
                </p>
                </div>
              ) : (
                ""
              )}
            </div> */}
          
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop6}>
                <span> If my referral subscribes every month will I still get paid my referral commission? </span>
                <span className="IconSpace">
                  {show6? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show6 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                  Yes, you earn upto 75% commission on any user your refer to our plaform.e.g if Tola refers Emeka and Emeka subscribe to our yearly affiliate program, Tola will recieve 75%( ₦7,500) and if it's a monthly subscription Tola will receive ₦750. if Emeka continue renewing his subscrption every mont or year Tola will keep earning his referral bonus for life.
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="homeChange">
              <div className="HowFarBro" onClick={drop7}>
                <span>  Can i invest my money with your company so you trade & bet for me and pay me percentage monthly?</span>
                <span className="IconSpace">
                  {show7? <FontAwesomeIcon icon={faMinus} className="PlusIcon" />:<FontAwesomeIcon icon={faPlus} className="PlusIcon" />}
                </span>
              </div>
              {show7 ? (
                <div>
                  <div className="Qac"></div>
                <p className="ILOVEK">
                 We or our Tipsters do not accept or manage users trading/ betting funds, trade or bet for any user. We only forecast the forex, crypto, binary market & predicts games. Users are responsible for managing their money with any brokers or bookies of their choice.
                </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          </div>
          </div>  
     

        
      </div>
<div className="bkFR">
       <div style={{padding:"0 10px"}}>
                <div className="MessageDivCov">
               <div className="Package_Up">
                   <h1 className="alap">Don't miss out on Fantastic News</h1>
                   <p className="Each_Time_your">
                       Subscribe to our newsletter and be the first to receive news.
                   </p>
                   <form>
                    <div className="newsInput">
                        <input type="email" placeholder="Enter Your Email"  className="newsBro"/>
                        <FontAwesomeIcon icon={faPaperPlane} className="EmailSentIcon" />
                    </div>
                   </form>
                   <img src="/img/news.svg" alt=""  className="messageImg"/>
               </div>
               <img src="/img/undrawWorld1.svg" alt="" className="papag" />
                <img src="/img/undrawWorld1.svg" alt="" className="papag1" />
               
               </div>
               </div>
               </div>
      <Footer />
      </div>
    );
}

export default Home2;
