import React from 'react'

const Token = () => {
  return (
    <div>
        <div>
             <div>
                <p>Navigating the world
of blockchain</p>
                   <p>In our digital world, trust, security, and efficiency are vital. Enter blockchain
technology, the game-changer.</p>
<div>
    <div>Get Started</div>
    <div>White paper</div>
</div>
             </div>
             <div><img src="" alt="" /></div>
        </div>
    </div>
  )
}

export default Token